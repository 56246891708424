<template>
  <span class="sound-effects">
    <SmartAudio v-if="sfx" :key="sfx.id" :src="sfx.src" :paused="false" />
    <SmartAudio
      v-if="mainTrackSource"
      :key="mainTrackSource"
      :loop="isMainTrackLooped"
      :volume="mainTrackVolume"
      :paused="isMainTrackPaused"
      :src="mainTrackSource"
      @play="mainPlay"
      ref="track"
    />
    <SmartAudio
      v-if="overlayTrackSource"
      :key="'overlay-' + overlayTrackSource"
      :src="overlayTrackSource"
      :paused="isOverlayTrackPaused"
      :volume="overlayTrackVolume"
      @loadedmetadata="overlayLoaded"
    />
    <v-flex class="sound-effects__debug" v-if="debug">
      <v-layout column>
        <v-flex
          d-flex
          style="margin-top: 10px"
          v-for="obj in Object.entries(Sfx)"
          :key="obj[1].id"
        >
          <WeveButton
            style="width: 100%"
            @click.alt.stop="updateTeamSoundEffect(obj[1])"
            @click="updateGameSoundEffect(obj[1])"
          >
            {{ obj[0] }}
          </WeveButton>
        </v-flex>
      </v-layout>
    </v-flex>
  </span>
</template>

<script>
import Vue from "vue"
import { mapActions, mapGetters } from "vuex"
import Mode from "@shared/enums/Mode"
import SmartAudio from "@/modules/smart-media/components/SmartMedia"
import _ from "lodash"
import Sfx from "@shared/Sfx"
import { WeveButton } from "@weve/ui"

const MAX_TIMESTAMP_EFFECT_DIFF = 3000

// here we track the number of components that should pause the audio track
export const state = Vue.observable({ media: {} })

const add = uid => {
  state.media = { ...state.media, [uid]: true }
}

const rm = uid => {
  const media = state.media
  delete media[uid]
  state.media = { ...media }
}

export const Media = { add, rm }

export default {
  name: "SmartMedia",
  components: {
    SmartAudio,
    WeveButton
  },
  data() {
    return {
      debug: false,
      Sfx,
      sfx: null,
      sfxTimeout: null,
      isOverlayExpired: false,
      isMainExpired: false
    }
  },
  computed: {
    ...mapGetters("soundeffect", ["getAudioLoopStatus"]),
    ...mapGetters("auth", ["user", "isHost"]),
    ...mapGetters(["game", "isAutopilotOn", "getCurrentMode", "chats"]),
    isPausedByMedia() {
      return Object.keys(state.media).length > 0
    },
    isPausedByMeeting() {
      return (
        (this.getCurrentMode === Mode.Meeting ||
          this.getCurrentMode === Mode.Theater) &&
        this.isAutopilotOn
      )
    },
    isMainTrackLooped() {
      return this.getAudioLoopStatus && !this.mainTrack?.playerSource
    },
    isViewerPresenter() {
      return this.$store.getters["group/isViewerPresenter"]
    },
    mainTrackVolume() {
      const number = parseInt(this.mainTrack?.volume) || 100
      if (this.isViewerPresenter) {
        const diff = 100 - number
        const perecent = 50
        const value = number + diff * (perecent / 100)
        return Math.round((value / 100) * 100) / 100
      }
      return Math.round((number / 100) * 100) / 100
    },
    mainTrackSource() {
      return this.mainTrack?.source
    },
    mainTrack() {
      return this.game?.audioTrack
    },
    isOverlayTrackPaused() {
      return (
        this.overlayTrack?.status === "pause" ||
        this.isPausedByMeeting ||
        this.isPausedByMedia ||
        this.isOverlayExpired
      )
    },
    isMainTrackPaused() {
      return (
        this.mainTrack?.status === "pause" ||
        this.isPausedByMeeting ||
        this.isPausedByMedia ||
        this.isMainExpired
      )
    },
    overlayTrack() {
      return this.game?.overlayTrack
    },
    overlayTrackSource() {
      return this.overlayTrack?.source
    },
    overlayTrackVolume() {
      const number = parseInt(this.overlayTrack?.volume) || 100
      return Math.round((number / 100) * 100) / 100
    },
    effects() {
      return [this.chats?.[this.user?.teamID]?.sfx || {}, this.game?.sfx || {}]
    },
    effect() {
      const [effect] = this.effects.sort(
        (a, b) => (parseInt(b.timestamp) || 0) - (parseInt(a.timestamp) || 0)
      )
      return effect?.src && effect
    }
  },
  async created() {
    // OMG why

    try {
      await this.fetchGameAudioTracks()
    } catch (e) {
      console.log(e)
    }

    try {
      await this.fetchHostAudioTracks()
    } catch (e) {
      console.log(e)
    }
  },
  watch: {
    // play sfx only if it is up to date
    effect(after, before) {
      if (after && !_.isEqual(after, before)) {
        clearTimeout(this.sfxTimeout)
        this.sfxTimeout = setTimeout(() => {
          this.sfx = null
        }, MAX_TIMESTAMP_EFFECT_DIFF)
        this.sfx = after
      }
    }
  },
  methods: {
    ...mapActions("soundeffect", [
      "updateGameSoundEffect",
      "updateTeamSoundEffect",
      "fetchGameAudioTracks",
      "fetchHostAudioTracks"
    ]),
    handleLocalPause(value) {
      this.isPausedLocally = value
    },
    overlayLoaded(e) {
      const { duration } = e.target
      const expiresAt = this.overlayTrack?.timestamp + duration * 1000 + 1000
      this.isOverlayExpired = expiresAt < Date.now()
    },
    mainPlay(e) {
      const { duration } = e.target
      const expiresAt = this.mainTrack?.timestamp + duration * 1000 + 1000
      this.isMainExpired = expiresAt < Date.now()
    }
  }
}
</script>

<style lang="scss">
.sound-effects {
  position: fixed;
  pointer-events: none;
  bottom: -300px;
  left: -300px;
  &__debug {
    pointer-events: initial;
    position: fixed;
    top: 300px;
    left: 100px;
    z-index: 1000;
  }
}
</style>
