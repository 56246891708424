var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    { staticClass: "sound-effects" },
    [
      _vm.sfx
        ? _c("SmartAudio", {
            key: _vm.sfx.id,
            attrs: { src: _vm.sfx.src, paused: false },
          })
        : _vm._e(),
      _vm.mainTrackSource
        ? _c("SmartAudio", {
            key: _vm.mainTrackSource,
            ref: "track",
            attrs: {
              loop: _vm.isMainTrackLooped,
              volume: _vm.mainTrackVolume,
              paused: _vm.isMainTrackPaused,
              src: _vm.mainTrackSource,
            },
            on: { play: _vm.mainPlay },
          })
        : _vm._e(),
      _vm.overlayTrackSource
        ? _c("SmartAudio", {
            key: "overlay-" + _vm.overlayTrackSource,
            attrs: {
              src: _vm.overlayTrackSource,
              paused: _vm.isOverlayTrackPaused,
              volume: _vm.overlayTrackVolume,
            },
            on: { loadedmetadata: _vm.overlayLoaded },
          })
        : _vm._e(),
      _vm.debug
        ? _c(
            "v-flex",
            { staticClass: "sound-effects__debug" },
            [
              _c(
                "v-layout",
                { attrs: { column: "" } },
                _vm._l(Object.entries(_vm.Sfx), function (obj) {
                  return _c(
                    "v-flex",
                    {
                      key: obj[1].id,
                      staticStyle: { "margin-top": "10px" },
                      attrs: { "d-flex": "" },
                    },
                    [
                      _c(
                        "WeveButton",
                        {
                          staticStyle: { width: "100%" },
                          on: {
                            click: [
                              function ($event) {
                                if (!$event.altKey) {
                                  return null
                                }
                                $event.stopPropagation()
                                return _vm.updateTeamSoundEffect(obj[1])
                              },
                              function ($event) {
                                return _vm.updateGameSoundEffect(obj[1])
                              },
                            ],
                          },
                        },
                        [_vm._v(" " + _vm._s(obj[0]) + " ")]
                      ),
                    ],
                    1
                  )
                }),
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }